import "./index.css";
import React, { useReducer, Reducer, useEffect } from "react";
import fileFixtures from "fixtures/files.json";
import { FileTree } from "components/FileTree";
import { Fixture, FileTree as Tree, FileNode } from "types";
import { FileContent, NoSelectedFile } from "components/FileContent";
import { createFileTree, fromFixtureToFile, FileContext } from "utilities";
import { Typography } from "components/Typography";
import { Color } from "styles-utilities";

enum FileRequestStatus {
  Init,
  Pending,
  Success,
  Error,
}

type FileAction =
  | { type: "fetching" }
  | { type: "files"; fixtures: Fixture[] }
  | { type: "error"; error: Error }
  | { type: "selectFile"; file: FileNode };

const reducer: Reducer<
  {
    status: FileRequestStatus;
    files: Tree;
    selectedFile: FileNode | undefined;
  },
  FileAction
> = (state, action) => {
  switch (action.type) {
    case "fetching":
      return { ...state, status: FileRequestStatus.Pending };
    case "files": {
      return {
        ...state,
        status: FileRequestStatus.Success,
        files: createFileTree(action.fixtures.map(fromFixtureToFile)),
      };
    }
    case "error": {
      return { ...state, status: FileRequestStatus.Error, error: action.error };
    }
    case "selectFile":
      return {
        ...state,
        selectedFile: action.file,
      };
    default:
      return state;
  }
};

const App: React.FC = () => {
  const [{ selectedFile, files }, dispatch] = useReducer(reducer, {
    status: FileRequestStatus.Init,
    selectedFile: undefined,
    files: {},
  });

  useEffect(() => {
    dispatch({ type: "fetching" });

    // Simulate asynchrony.
    Promise.resolve(fileFixtures).then(fixtures => {
      dispatch({ fixtures, type: "files" });
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">ElementAI Frontend Challenge</header>
      <div className="App-content">
        <FileContext.Provider
          value={{
            selectedFile,
            selectFile: file => dispatch({ type: "selectFile", file }),
          }}
        >
          <FileTree tree={files} />
          {selectedFile ? (
            <FileContent file={selectedFile} />
          ) : (
            <NoSelectedFile>
              <Typography css={{ textAlign: "center" }}>
                <span role="img" aria-label="Magnifying Glass">
                  🔍
                </span>
              </Typography>
              <Typography
                variant="subtitle"
                css={{
                  marginTop: 0,
                  color: Color.DarkGray,
                  textAlign: "center",
                }}
              >
                It looks like you haven't selected a file yet
              </Typography>
              <Typography
                variant="paragraph"
                css={{ color: Color.DarkGray, textAlign: "center" }}
              >
                Select a file from the list and preview its content.
              </Typography>
            </NoSelectedFile>
          )}
        </FileContext.Provider>
      </div>
    </div>
  );
};

export default App;
