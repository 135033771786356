export interface File {
  path: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
}

export type Fixture = Pick<File, "path" | "content"> & {
  createdAt: string;
  updatedAt: string;
};

export enum NodeType {
  Directory,
  File,
}

export type FileNode = File & {
  type: NodeType.File;
  name: string;
  extension: string;
};

export type DirectoryNode = {
  type: NodeType.Directory;
  name: string;
  children: FileTree;
};

export type FileTree = {
  [name: string]: DirectoryNode | FileNode;
};

export type FileSelector = (file: FileNode) => void;

export interface Dictionary<T> {
  [x: string]: T;
}
