import { createStyles, Color } from "styles-utilities";
import { css } from "@emotion/core";

export const flexCenterAligned = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const activeFileStyles = css({
  color: Color.Primary,
});

export const truncateTextStyles = css({
  maxWidth: 250,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const styles = createStyles({
  treeRoot: {
    width: 400,
    backgroundColor: Color.Gray,
    border: `1px solid ${Color.Gray}`,
    padding: "25px 0",
    flexGrow: 0,
    boxSizing: "border-box",

    "@media screen and (max-width: 770px)": {
      width: "100%",
    },
  },

  heading: {
    margin: "0 0 15px 10px",
  },

  directory: {
    width: "100%",
    backgroundColor: Color.White,
    position: "relative",
    marginBottom: 2,

    "&:before": {
      content: '""',
      position: "absolute",
      opacity: 0,
      width: 3,
      top: 0,
      right: 0,
      bottom: 0,
      transition: "opacity .2s ease",
      zIndex: 2,
    },
  },

  header: {
    justifyContent: "space-between",
    background: "none",
    border: 0,
    appearance: "none",
    width: "100%",
    cursor: "pointer",
    padding: "12px 20px 12px",
    position: "relative",

    "&:focus": {
      outline: 0,
      transition: "box-shadow 0.2s ease",
      boxShadow: `inset 0 -2px 0 0 #714bbd96`,
    },
  },

  label: {
    backgroundColor: Color.Gray,
    color: Color.Primary,
    borderRadius: "50%",
    padding: 5,
    lineHeight: 1,
    boxSizing: "border-box",
    fontSize: "0.8rem",
    width: 20,
    height: 20,
  },

  directoryName: {
    margin: "0px 10px",
    fontSize: "1rem",
    fontWeight: 500,
    display: "inline-block",
    lineHeight: 1.4,
    transition: "color .2s ease",
  },

  directoryList: {
    listStyleType: "none",
    paddingLeft: 32,
    margin: 0,
    overflow: "hidden",
    height: "auto",
  },

  file: {
    padding: "12px 20px 12px 20px",
    display: "block",
    textDecoration: "underline",
    color: Color.DarkerGray,
    border: 0,
    appearance: "none",
    background: "none",
    cursor: "pointer",
    fontSize: "1rem",

    "&:focus": {
      outline: 0,
      color: Color.Primary,
    },
  },
});
