/** @jsx jsx */

import React, { useState } from "react";
import { jsx, css } from "@emotion/core";
import { DirectoryNode, FileNode, NodeType } from "types";
import { DirectoryIcon } from "components/FileTree/Details";
import { Color } from "styles-utilities";
import { either, FileContext } from "utilities";
import {
  styles,
  flexCenterAligned,
  activeFileStyles,
  truncateTextStyles,
} from "./styles";
import { Typography } from "components/Typography";

export const NodeTree: React.FC<FileNode | DirectoryNode> = props => {
  switch (props.type) {
    case NodeType.Directory:
      return <Directory {...props} />;
    case NodeType.File:
      return <File {...props} />;
    default:
      return null;
  }
};

export const Directory: React.FC<DirectoryNode> = ({ name, children }) => {
  const [shouldOpen, setOpenState] = useState(false);
  const whenOpen = either(shouldOpen);
  const nodes = Object.values(children) as (FileNode | DirectoryNode)[];

  return (
    <div
      css={css([
        styles.directory,
        {
          "&:before": {
            opacity: whenOpen(1, 0),
            backgroundColor: Color.Primary,
          },
        },
      ])}
    >
      <button
        css={css([flexCenterAligned, styles.header])}
        onClick={() => setOpenState(state => !state)}
      >
        <div css={{ display: "flex" }}>
          <DirectoryIcon
            fill={whenOpen(Color.Primary, Color.DarkGray)}
            width={24}
            height={22}
          />
          <Typography
            variant="subtitle"
            css={css([
              styles.directoryName,
              truncateTextStyles,
              { color: whenOpen(Color.Primary, "inherit") },
            ])}
          >
            {name}
          </Typography>
        </div>
        <span css={css(flexCenterAligned, [styles.label])}>{nodes.length}</span>
      </button>

      {nodes.length > 0 && (
        <ul
          css={css([
            styles.directoryList,
            {
              maxHeight: whenOpen(600, 0),
              visibility: whenOpen("inherit", "hidden"),
              transition: whenOpen("max-height 0.7s", "max-height 0.2s"),
            },
          ])}
        >
          {nodes.map(node => (
            <li key={node.name}>
              <NodeTree {...node} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const File: React.FC<FileNode> = file => {
  return (
    <FileContext.Consumer>
      {({ selectedFile, selectFile }) => (
        <button
          onClick={() => selectFile(file)}
          css={css([
            styles.file,
            truncateTextStyles,
            selectedFile && selectedFile.path === file.path && activeFileStyles,
          ])}
        >
          {file.name}
        </button>
      )}
    </FileContext.Consumer>
  );
};
