import {
  File,
  DirectoryNode,
  FileNode,
  NodeType,
  FileTree,
  Fixture,
  FileSelector,
} from "types";
import { createContext } from "react";

type Node = DirectoryNode | FileNode;

const isDirectoryNode = (node?: Node): node is DirectoryNode => {
  if (node) return node.type === NodeType.Directory;
  return false;
};

const traverse = (
  accumulator: FileTree,
  file: File,
  [head, ...tail]: string[],
): FileTree => {
  if (!head) return accumulator;

  const [, extension] = head.split(".");
  const ifIsFileElse = either(Boolean(tail.length === 0));

  const data = ifIsFileElse<() => FileNode, () => DirectoryNode>(
    () => ({
      ...file,
      extension,
      name: head,
      type: NodeType.File,
    }),
    () => {
      const previous = accumulator[head];
      const node = isDirectoryNode(previous) ? previous.children : {};
      return {
        name: head,
        type: NodeType.Directory,
        children: traverse(node, file, tail),
      };
    },
  );

  return { ...accumulator, [head]: data() };
};

export const createFileTree = (files: File[]) => {
  return files.reduce((acc, file) => {
    const [, ...paths] = file.path.split("/");
    return traverse(acc, file, paths);
  }, {});
};

export const either = (value: boolean) => <L, R>(left: L, right: R): L | R => {
  if (value) return left;

  return right;
};

export const fromFixtureToFile = ({
  createdAt,
  updatedAt,
  ...fixture
}: Fixture): File => ({
  ...fixture,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

export const FileContext = createContext<{
  selectFile: FileSelector;
  selectedFile?: FileNode;
}>({
  selectedFile: undefined,
  selectFile: () => {},
});
