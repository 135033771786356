/** @jsx jsx */

import React from "react";
import { css, jsx, Interpolation } from "@emotion/core";

type Variant = "title" | "subtitle" | "paragraph";

export interface TypographyProps {
  variant?: Variant;
  css?: Interpolation;
}

const variantTags: Record<Variant, string> = {
  title: "h1",
  subtitle: "h2",
  paragraph: "p",
};

export const Typography: React.FC<TypographyProps> = ({
  variant = "title",
  css: styles = [],
  ...props
}) => {
  const Component = variantTags[variant];

  return (
    <Component
      css={css([{ fontWeight: 400, textAlign: "inherit" }, styles])}
      {...props}
    />
  );
};
