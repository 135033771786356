/** @jsx jsx */

import React, { SVGAttributes } from "react";
import { jsx } from "@emotion/core";
import { Color, createStyles } from "styles-utilities";
import { Dictionary } from "types";

const styles = createStyles({
  label: {
    borderRadius: 20,
    padding: "5px 8px",
    fontSize: "0.8rem",
    backgroundColor: Color.Gray,
  },
});

export const DirectoryIcon: React.FC<SVGAttributes<SVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    css={{ transition: "fill 0.2s ease" }}
    {...props}
  >
    <path d="M9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
  </svg>
);

export const FileIcon: React.FC<SVGAttributes<SVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    css={{ transition: "fill 0.2s ease" }}
    {...props}
  >
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
  </svg>
);

const extensions: Dictionary<{ color: Color; displayName: string }> = {
  md: { color: Color.Orange, displayName: "Markdown" },
  txt: { color: Color.Gray, displayName: "Text" },
  ts: { color: Color.Blue, displayName: "TypeScript" },
  json: { color: Color.Green, displayName: "JSON" },
};

export const FileLabel: React.FC = props => (
  <span css={styles.label} {...props} />
);

export interface FileTypeLabelProps {
  extension: string;
}

export const FileTypeLabel: React.FC<FileTypeLabelProps> = ({ extension }) => {
  const { color = Color.Primary, displayName = "Unknown type" } =
    extensions[extension] || {};

  return <FileLabel css={{ backgroundColor: color }}>{displayName}</FileLabel>;
};
