import { CSSObject } from "@emotion/css";

export enum Color {
  Primary = "#714BBD",
  Gray = "#EAEBEB",
  DarkGray = "#B6B6B6",
  DarkerGray = "#3e3e3e",
  White = "#FFF",
  Orange = "#F2B560",
  Blue = "#60C1F2",
  Green = "#56D88C",
}

export function createStyles<T extends string | number>(
  styles: Record<T, CSSObject>,
) {
  return styles as Record<keyof typeof styles, CSSObject>;
}
