/** @jsx jsx */

import React, { memo } from "react";
import { jsx } from "@emotion/core";
import { FileTree as Tree } from "types";
import { NodeTree } from "components/FileTree/Node";
import { Typography } from "components/Typography";
import { styles } from "./styles";

export type FileTreeProps = {
  tree: Tree;
};

export const FileTree: React.FC<FileTreeProps> = memo(({ tree }) => {
  return (
    <div css={styles.treeRoot}>
      <Typography variant="subtitle" css={styles.heading}>
        Your secret things
      </Typography>
      {Object.values(tree).map(node => (
        <NodeTree {...node} key={node.name} />
      ))}
    </div>
  );
});
