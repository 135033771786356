/** @jsx jsx */

import React from "react";
import { jsx } from "@emotion/core";
import { FileNode } from "types";
import {
  FileIcon,
  FileTypeLabel,
  FileLabel,
} from "components/FileTree/Details";
import { Color } from "styles-utilities";
import { styles } from "./styles";
import { Typography } from "components/Typography";

export interface FileContentProps {
  file: FileNode;
}

export const FileContent: React.FC<FileContentProps> = ({ file }) => {
  return (
    <div css={styles.fileContainer}>
      <header>
        <FileIcon fill={Color.Gray} width={72} height={72} />
        <Typography css={styles.fileName}>{file.name}</Typography>
      </header>
      <div css={styles.labels}>
        <FileTypeLabel extension={file.extension} />
        <FileLabel>Created on {file.createdAt.toLocaleDateString()}</FileLabel>
        <FileLabel>Updated on {file.updatedAt.toLocaleDateString()}</FileLabel>
      </div>

      {typeof file.content === "string" ? (
        <section css={styles.fileContent}>{file.content}</section>
      ) : null}
    </div>
  );
};

export const NoSelectedFile: React.FC = ({ children }) => (
  <div css={styles.fileContainer}>{children}</div>
);
