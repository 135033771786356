import { createStyles } from "styles-utilities";

export const styles = createStyles({
  fileContainer: {
    padding: "65px 40px 40px",
    flexGrow: 1,

    "@media screen and (max-width: 770px)": {
      padding: "20px 20px 50px",
    },

    header: {
      display: "flex",
      alignItems: "center",
    },
  },

  labels: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    margin: 10,

    ">*": {
      margin: 5,
      "&:last-child": { marginRight: 0 },
    },
  },

  fileName: {
    maxWidth: 400,
    overflow: "auto",
    "@media screen and (max-width: 992px)": {
      maxWidth: 300,
    },
  },

  fileContent: {
    marginTop: 35,
    maxWidth: 600,
    maxHeight: 800,
    overflow: "auto",
    padding: "25px 50px",
    boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.1)",
    lineHeight: 1.4,
    fontSize: "1.1rem",
  },
});
